import * as React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import ContactForm from '../components/contactForm';
import Footer from '../components/footer';

import './news.scss';

const NewsPage = ({ data: { news: { edges } } }) => {
  const { t } = useTranslation();
  const items = edges
    .map(({ node: { frontmatter } }) => frontmatter)
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const news = items.map(({ title, topic, formattedDate, slug, image }) => {
    return <li className="news-item" key={slug}>
      <Link to={`/news${slug}`}>
        <div className="image" alt={title} style={{backgroundImage: `url(/images/news/${slug}.png)`}}>
          <div className="brand">{topic}</div>
          <div className="info">
            <h3>{title}</h3>
            <p>{formattedDate}</p>
          </div>
        </div>
      </Link>
    </li>;
  });

  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Infocus | Homepage</title>
      <meta name="keywords" content="Infocus, Data-Mining, información, análisis de datos" />
      <meta name="description" content="La información hace la diferencia"/>
      <meta property="og:title" content="Infocus | Homepage"></meta>
      <meta property="og:description" content="Infocus, Data-Mining, información, análisis de datos"></meta>
    </Helmet>

    <Layout>
      <section id='news' className="News">
        <h2 className="title">{t('news.title')}</h2>
        <ul className="news-list">{news}</ul>
      </section>

      <ContactForm/>
      <Footer/>
    </Layout>
  </>);
};

export default NewsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    news: allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            title
            topic
            formattedDate: date(formatString: "DD/MM/YYYY")
            date
            slug
          }
        }
      }
    }
  }
`;
